import { Filters, FilterType } from "~/types/filters";

/**
 * Generate filters data set for POST request
 * @param filters - The object containing all filter settings.
 * @param payload - The object containing additional data to be included in the query.
 */
export const useNewPostFiltersQS = (
  filters: Filters,
  payload?: Record<string, any>,
  usePrefix?: boolean
) => {
  const dataSet = {};
  let taxonomiesValues = [];

  Object.entries(filters).forEach(([key, filter]) => {
    if (!filter.chosenOptions.length && !filter.toggle) return;

    const values = filter.chosenOptions.map((o) => o.value);

    // Price filter logic
    if (filter.type === FilterType.PRICE_RANGE) {
      dataSet[`${key}_from`] = values[0];
      dataSet[`${key}_to`] = values[1];

      return;
    }

    // Additional types filter
    if ("additional_types" === key) {
      values.forEach((i) => {
        dataSet[i] = true;
      });

      return;
    }

    // Date Range filters
    if ([FilterType.DATE_RANGE, FilterType.MAIN_DATE].includes(filter.type)) {
      let date = "" + values[0],
        range = date.split("_");

      if (2 === range.length) {
        dataSet[`${key}_from`] =
          `${useDateFormatLib(range[0], "yyyy-mm-dd")} 00:00:00`;
        dataSet[`${key}_to`] =
          `${useDateFormatLib(range[1], "yyyy-mm-dd")} 23:59:59`;
      }

      return;
    }

    // Date Range filters
    if (FilterType.NEW_DATE_RANGE === filter.type) {
      let date = "" + values[0],
        range = date.split("_");

      if (2 === range.length) {
        dataSet[key] = {
          from: useDateFormatLib(range[0], "yyyy-mm-dd 00:00:00"),
          to: useDateFormatLib(range[1], "yyyy-mm-dd 23:59:59"),
        };
      }

      return;
    }

    // Boolean filters
    if (FilterType.SWITCHER === filter.type && 0 < values.length) {
      dataSet[key] = "true" === values[0];

      return;
    }

    // Taxonomies filter
    if (["taxonomies_categories", "taxonomies_tags"].includes(key)) {
      taxonomiesValues = taxonomiesValues.concat(values);
      dataSet[`taxonomies`] = taxonomiesValues;

      return;
    }

    // Base filter
    dataSet[key] = filter.isSingle
      ? 0 < values.length
        ? values[0]
        : ""
      : values;
  });

  // if having some filters in the payload
  const remainingFilters =
    payload &&
    Object.fromEntries(
      Object.entries(payload).filter(([key]) =>
        usePrefix ? key.startsWith("filter") : !key.startsWith("filter")
      )
    );

  return usePrefix
    ? {
        filters: {
          ...dataSet,
          ...(remainingFilters && { ...remainingFilters }),
        },
      }
    : { ...dataSet, ...(remainingFilters && { ...remainingFilters }) };
};
